<template>
  <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      loading: true,
      color: "#2A409A",
      size: "50px",
    };
  },
};
</script>
