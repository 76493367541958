<template>
  <v-row justify="space-around">
    <v-col cols="auto" style="overflow: hidden">
      <v-dialog
        v-model="showDialog"
        transition="dialog-top-transition"
        persistent
        max-width="400px"
        max-height="400px"
        style="overflow:hidden"
      >
        <v-card>
          <v-card-text>
            <div class="login_box">
              <div class="centeredSpinnerWrapper" v-if="showSpinner">
                <SpinnerComponent class="centeredSpinner"></SpinnerComponent>
              </div>
              <div v-else>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 450px;
                    overflow: hidden;
                  "
                >
                  <div
                    style="
                      background-color: #eb5757;
                      width: 95%;
                      max-height: 40px;
                      margin: 0px auto;
                      padding: 8px;
                      border-radius: 8px;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                  >
                    <img
                      style=" padding-left:5px; padding-top:0px"
                      src="@/assets/alert.svg"
                    />
                    <p
                      style="
                        font-size: 0.8rem;
                        padding: 0px 0px 0px 0px;
                        font-weight: 600;
                        color: white;
                      "
                    >
                      Your session has expired due to Inactivity
                    </p>
                  </div>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      margin: 10px 0px 10px 0px;
                    "
                  >
                    <h4
                      style="color: gray; font-weight: 600px; font-size: 1rem"
                    >
                      Please log in to continue
                    </h4>
                    <img
                      style="
                        width: 100px;
                        height: 100px;
                        margin: 20px 0px 20px 0px;
                      "
                      src="@/assets/blueJ_logo_blue.svg"
                    />
                  </div>
                  <v-btn
                    class="login_btn"
                    v-on:click="getAuthCode"
                    depressed
                    outlined 
                    :disabled="!isGauthInit"
                    style="
                      height: 50px;
                      width: 79%;
                      border: 1px solid black;
                      font-size: 1rem;
                      font-weight: 600;
                      margin: 10px; 
                      color: black;
                      padding:20px; "
                  >
                    <img :style="!isGauthInit ? 'opacity:50%;' : ''" style="height: 30px; width: 30px" src="@/assets/Group.svg" />
                    &nbsp; &nbsp; <p class="btn_name">Login with Google</p>
                  </v-btn>

                  <v-btn
                    :href="oneLoginAuthURL"
                    :disabled="!hasOneLoginUrl"
                    depressed
                    outlined
                    style="width: 80%; height: 50px; margin: 5px 0px 5px 0px"
                  >
                    <img
                      :style="!hasOneLoginUrl ? 'opacity:50%;' : ''"
                      style="height: 30px; width: 30px"
                      src="@/assets/Vector.svg"
                    />
                    &nbsp; &nbsp;
                    <font style="font-weight: 600">Login with OneLogin</font>
                  </v-btn>
                  <v-btn
                    depressed
                    outlined
                     @click="showLoginDialog"
                    style="width: 80%; height: 50px; margin: 5px 0px 5px 0px"
                  >
                    &nbsp; &nbsp;
                    <font style="font-weight: 600">Login with Email</font>
                  </v-btn>
                </div>
                <div v-if="showUnauthorizedDialog">
                  <MessageDialog
                    :displayDialog="showMessageDialog"
                    :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
                    @closeMessageDialog="closeMessageDialog"
                  ></MessageDialog>
                </div>
                <div v-if="setloginDialog">
                  <EmailLoginComponent
                    @loginWithEmail="loginWithEmail"
                    @clickCloseLoginDialog="clickCloseLoginDialog"
                  ></EmailLoginComponent>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <!-- </template> -->
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import MessageDialog from "../components/MessageDialog.vue";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import Client from "@onelogin/sdk";
import EmailLoginComponent from "../components/EmailLoginComponent.vue";
import {getFromLocalStorage, setToLocalStorage, removeFromLocalStorage} from "../store/localStorageInterface"
const axios = require("axios");

export default {
  components: {
    MessageDialog,
    SpinnerComponent,
    EmailLoginComponent
  },
  data() {
    return {
      showUnauthorizedDialog: false,
      dialogText: "You are not authorized",
      oneLoginAuthURL: "",
      showDialog: true,
      showSpinner: false,
      showMessageDialog: false,
      errorMessageShow: false,
      errorMessageObject: {},
      okMessageObject: {},
      isGauthInit: false,
      setloginDialog:false,
      gIdToken: "",
      gTdExpiry: "",
      emailIdToken:"",
      emailIdExpiry:"",
      isGauthInit:false
    };
  },
  computed: {
    hasOneLoginUrl() {
      return this.oneLoginAuthURL !="" ? true : false
    },
  },
  async mounted() {
    this.initClient()
    const onelogin = new Client({
      baseURL: process.env.VUE_APP_ONELOGIN_BASEURL,
    });

    let pkceClient = onelogin.pkce.Configure({
      redirectURL: process.env.VUE_APP_ONELOGIN_CALLBACK_URL,
      clientID: process.env.VUE_APP_ONELOGIN_CLIENT_ID,
      baseURL: process.env.VUE_APP_ONELOGIN_BASEURL,
    });
    this.clearAuthTokenAndCodeVerifier();
    pkceClient.CreateAuthURL().then((url) => {
      this.oneLoginAuthURL = url;
    });
  },
  methods: {
    initClientHelper(){
      this.client = google.accounts.oauth2.initCodeClient({
          client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/userinfo.email',
          ux_mode: 'popup',
          callback: (response) => {
            let authCode = response.code;
            if (authCode) {
            axios({
              method: "post",
              url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
              data: { authCode: authCode, authType: "gauth", },
              headers: {
                "content-type": "application/json",
              },
            }).then((response) => {
              if (response.data.message == "Success") {
                if (response.data.userNotificationsData) {
                  this.$store.dispatch("replaceNotifications", response.data.userNotificationsData);
                }
                this.showSpinner = true;
                this.gIdToken = response.data.id_token;
                this.gTdExpiry = response.data.expiry;
                if(response.data.userId){
                  setToLocalStorage("userId", response.data.userId);
                }
                if (response.data.picture) {
                  setToLocalStorage("loginImage", response.data.picture);
                }
              this.completeGoogleAuthentication();
              } else {
                this.clearAuthTokenAndCodeVerifier();
                this.showSpinner = false;
                this.showUnauthorizedDialog = true;
              }
            }).catch((error) => {
              if (error.response && error.response.status && error.response.status == 401) {
                this.clearAuthTokenAndCodeVerifier();
                pkceClient.CreateAuthURL().then((url) => {
                  this.showSpinner = false;
                  this.showUnauthorizedDialog = true;
                  this.oneLoginAuthURL = url;
                });
              } else{
                if(error.response && error.response.data && error.response.data.errorObject) {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: error.response.data.errorObject.error_code,
                    error_uuid: error.response.data.errorObject.error_uuid,
                  };
                } else {
                  this.errorMessageObject = {
                    errorMessageShow: this.errorMessageShow,
                    error_code: "",
                    error_uuid: "",
                  };
                }
                this.showMessageDialog = true;
                this.errorMessageShow = true;
              }
            });
          }
        },
      });
    },
    initClient() {
          let that = this;
          let checkGauthLoad = setInterval(function () {
          if(google){
            that.isGauthInit = true;
            that.initClientHelper();
          }
          if(that.isGauthInit) clearInterval(checkGauthLoad);
        }, 200);
    },
    showLoginDialog() {
      this.setloginDialog=true;
    },
    clickCloseLoginDialog() {
      this.setloginDialog=false;
    },
    closeMessageDialog(emitObject) {
      this.showUnauthorizedDialog = false;
      var closeDialogFlag = false;
      if(emitObject && emitObject.errorDisplayMessage){
        closeDialogFlag = emitObject.errorDisplayMessage
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    loginWithEmail (UserDetails) {
      axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL + "/verifyUser",
        data: { 
          "authType": "emailWithPassword",
          "UserDetails":UserDetails 
        },
        headers: {
          "content-type": "application/json",
        },
      }).then ((loginResponse)=> {
        if (loginResponse.data.message == "Success") {
          if (loginResponse.data.userNotificationsData) {
            this.$store.dispatch("replaceNotifications", loginResponse.data.userNotificationsData);
          }
          this.emailIdToken = loginResponse.data.id_token;
          this.emailIdExpiry = loginResponse.data.expiry;
          setToLocalStorage("userId", loginResponse.data.userId);
          setToLocalStorage("authType", "email");
          setToLocalStorage("token", this.emailIdToken);
          setToLocalStorage("id_token_exp", this.emailIdExpiry);
          this.showSpinner = true;
          if (localStorage.getItem("currentPath")) {
            var path = localStorage.getItem("currentPath").split('#');
            var routePath = path[0];
            if (path.length>1)
            {
              var pathUserId = path[1];
              if(pathUserId == getFromLocalStorage('userId')) {
                this.$router.push({
                  path: routePath,
                });
              }
              else {
                localStorage.removeItem("currentPath");
                this.$router.push({
                  name: "Home",
                }); 
              }
            }
            else {
              localStorage.removeItem("currentPath");
              this.$router.push({
                name: "Home",
              });
            }
          } else {
            this.$router.push({
              name: "Home",
            });
          }
          this.showSpinner = false;
          this.$emit("redirectPage");
        }
      }).catch ((error)=> { 
        if (error.response && error.response.status && error.response.status == 401) {
          this.clearAuthTokenAndCodeVerifier();
        }
        else {
          if(error.response && error.response.data && error.response.data.errorObject) {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: error.response.data.errorObject.error_code,
              error_uuid: error.response.data.errorObject.error_uuid,
            };
          } else {
            this.errorMessageObject = {
              errorMessageShow: this.errorMessageShow,
              error_code: "",
              error_uuid: "",
            };
          }
          this.showMessageDialog = true;
          this.errorMessageShow = true;
        }
      })
    },
    getAuthCode() {
      this.client.requestCode();
    },
    completeGoogleAuthentication(){
      setToLocalStorage("authType", "gauth");
      setToLocalStorage("token", this.gIdToken);
      setToLocalStorage("id_token_exp", this.gTdExpiry);
      this.showSpinner = false;
      this.$emit("redirectPage");
      if (localStorage.getItem("currentPath")) {
        var path = localStorage.getItem("currentPath").split('#');
        var routePath = path[0];
        if(path.length>1)
        {
          var pathUserId = path[1];
          if(pathUserId == getFromLocalStorage('userId')){
            this.$router.push({
              path: routePath,
            });
          }
          else{
            localStorage.removeItem("currentPath");
            this.$router.push({
              name: "Home",
            });
          }
        }
        else{
          localStorage.removeItem("currentPath");
          this.$router.push({
            name: "Home",
          });
        }
      } else {
        this.$router.push({
          name: "Home",
        });
      }
    },
    clearAuthTokenAndCodeVerifier() {
      if(this.$route.query.code){
        this.$router.replace({ query: null });
      }
      removeFromLocalStorage("token");
      let secondarySupportLanguage = getFromLocalStorage("secondarySupportLanguage");
      if (secondarySupportLanguage) {
        removeFromLocalStorage('secondarySupportLanguage');
      }
      removeFromLocalStorage("authType");
      removeFromLocalStorage("fullName");
      removeFromLocalStorage("userId");
      localStorage.removeItem("auth-url");
      localStorage.removeItem('code-verifier');
    },
  },
};
</script>
<style scoped>
.login_btn{
      width:100%;
      height:40px;
    }
.btn_name{
  margin: 0px;
}    
</style>