<template>
  <div>
    <v-dialog
      persistent
      v-model="dialog"
      max-width="88%"
      max-height="100%"
      style="display: flex; flex-direction: column"
    >
      <div style="background-color: #ffffff">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 104px;
            border-bottom: 1px solid #d5dade;
            padding: 32px;
          "
        >
          <div
            max-width="100%"
            style="display: flex; align-items: Right; flex-direction: column"
          >
            <span
              style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
              "
              >User Logs</span
            >

          </div>
          <v-icon style="color: blue; cursor: pointer" @click="clickClose()"
            >mdi-close</v-icon
          >
        </div>
      </div>
      <div style="width: 100%; height: 100%; background-color: #ffffff">
        <div
          class="pl-2.5 py-5 px-3"
          style="
            float: left;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            width: 100%;
          "
        >
          <v-row
            class="py-1"
            style="margin: 10px                
                display: flex;
                justify-content: space-between;
                align-items: center;
                "
          >
            <div
              style="display: flex; align-items: Right; flex-direction: column"
            >
              <v-text-field
                style="
                  border: 1px solid #bdbdbd;
                  background: #ecf0fe;
                  border-radius: 4px;
                "
                solo
                flat
                dense
                hide-details="auto"
                :label="searchFieldText"
                clearable
                clear-icon="mdi-close"
                @click:clear="getSearchRecordEmpty()"
                v-model="searchValue"
                @keyup.enter="getSearchRecord()"
                append-icon="mdi-magnify"
                @click:append="getSearchRecord"
              >
              </v-text-field>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 57%;
                padding: 0px;
              "
            >
              <v-autocomplete
                outlined
                :menu-props="{ offsetY: true }"
                deletable-chips
                small-chips
                dense
                hide-details
                background-color="white"
                height="20px"
                v-model="selectedApplication"
                :items="listofApplication"
                item-value="id"
                item-text="name.en"
                label="Select Application"
                :rules="required"
                @change="getApplicationLogs"
              ></v-autocomplete>
              <div
                style="margin-left: 10px; position: relative"
                class="dateRange_textField"
              >
                <v-menu v-model="menu" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      outlined
                      dense
                      label="Date range"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="margin-top: 5px"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    class="custmon_datePicker"
                    v-model="selectedDates"
                    :range="true"
                    @input="onInput()"
                    :max="maxDate"
                  >
                    <template>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          elevation="0"
                          class="btn_actions"
                          @click="clearDates()"
                          >Clear</v-btn
                        >
                      </v-card-actions>
                    </template></v-date-picker
                  >
                </v-menu>
                <div style="position: absolute; bottom: 100%; left: 0">
                  <v-snackbar
                    v-model="snackbar"
                    :color="snackbarColor"
                    :timeout="snackbarTimeout"
                    class="custom-snackbar"
                  >
                    {{ snackbarMessage }}
                  </v-snackbar>
                </div>
              </div>
              <v-btn
                style="
                  margin: 10px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 8px 16px;
                  background: #456cf4;
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.01em;
                  color: #ffffff;
                "
                class="elevation-0 rounded-lg"
                @click="getApplicationLogs()"
                :disabled="isDisabled"
                >Apply</v-btn
              >
              <v-btn
                class="elevation-0 rounded-lg"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  padding: 8px 16px;
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.01em;
                  border: 1px solid gray;
                "
                @click="resetAllFilters()"
                >Reset</v-btn
              >
            </div>
          </v-row>
          <v-row style="display: flex; margin: 10px" class="userlogs_table">
            <v-data-table
              style="width: 100%; justify-content: center; align-items: center"
              height="calc(70vh - 180px)"
              :headers="headers"
              :items="dataItems"
              :loading="loading"
              :no-data-text="emptyText"
              class="elevation-0 customStyle rounded-lg"
              disable-pagination
              hide-default-footer
              multi-sort
              :fixed-header="true"
            >
              <template v-slot:item.RecordId="{ item }">
                <span class="headerSName">{{ item.RecordId }}</span>
              </template>
              <template v-slot:item.time="{ item }">
                <div style="display: flex; flex-direction: Column;">
                <span class="headerSName">{{ item.time[0] }}</span>
                <span class="headerSName">{{ item.time[1] }}</span>
                </div>
              </template>
              <template v-slot:item.ActionType="{ item }">
                <div
                  style="
                    display: flex;
                    flex-direction: Column;
                    align-items: right;
                    padding-right: 8px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                  "
                >
                  <span class="headerSName" style=""
                    >{{ item.ActionType }} in</span
                  >
                  <v-chip
                    small-chips
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      padding: 8px 16px;
                      margin-top: 8px;
                      margin-bottom: 8px;
                      height: 30px;
                      background: #03bb9d;
                      border-radius: 4px;
                      color: #ffffff;
                    "
                    >{{ applicationsObject[item.ApplicationId] }}</v-chip
                  >
                </div>
              </template>
              <template v-slot:item.changes="{ item }">
                <div v-if="item.ActionType == 'Insert'">
                  <span class="headerSName">Record created in stage </span>
                  <v-chip
                    small-chips
                    style="
                      color: #ffffff;
                      background-color: #5CBCCA;
                      margin: 8px;
                      border-radius: 4px;
                    "
                    >{{ item.To_Stage }}</v-chip
                  >
                  <ul class="bullet-points">
                    <li v-if="item.New_Assignee_Group">
                      <span class="headerSName" v-if="item.New_Assignee_Group">
                        Assigned to group </span
                      ><span
                        v-if="item.New_Assignee_Group"
                        style="color: ##212332; font-weight: 500"
                        >{{ item.New_Assignee_Group }}</span
                      >
                    </li>
                    <li v-if="item.New_Assignee">
                      <span class="headerSName" v-if="item.New_Assignee">
                        Assigned to user
                      </span>
                      <span
                        v-if="item.New_Assignee"
                        style="color: ##212332; font-weight: 500"
                        >{{ item.New_Assignee }}</span
                      >
                    </li>
                  </ul>
                </div>
                <div v-if="item.ActionType == 'Edit'">
                  <span class="headerSName">Record updated </span>
                  <ul class="bullet-points">
                    <li
                      v-if="
                        item.Old_Assignee &&
                        item.New_Assignee &&
                        item.New_Assignee != item.Old_Assignee
                      "
                    >
                      <span
                        v-if="
                          item.Old_Assignee &&
                          item.New_Assignee &&
                          item.New_Assignee != item.Old_Assignee
                        "
                      >
                        <span class="headerSName"
                          >and assignee changed from
                        </span>
                        <span style="color: ##212332; font-weight: 500"
                          >{{ item.Old_Assignee }}
                        </span>
                        <span class="headerSName"> to </span>
                        <span style="color: ##212332; font-weight: 500"
                          >{{ item.New_Assignee }}
                        </span>
                      </span>
                    </li>
                    <li v-if="!item.Old_Assignee && item.New_Assignee">
                      <span v-if="!item.Old_Assignee && item.New_Assignee">
                        <span class="headerSName">Assignee set to </span>
                        <span style="color: ##212332; font-weight: 500">{{
                          item.New_Assignee
                        }}</span>
                      </span>
                    </li>
                    <li v-if="item.Old_Assignee && !item.New_Assignee">
                      <span
                        v-if="item.Old_Assignee && !item.New_Assignee"
                        class="headerSName"
                        >and unassigned from
                      </span>
                    </li>
                    <li v-if="item.Old_Assignee && !item.New_Assignee">
                      <span
                        v-if="item.Old_Assignee && !item.New_Assignee"
                        style="color: ##212332; font-weight: 500"
                      >
                        {{ item.Old_Assignee }}</span
                      >
                    </li>
                  </ul>
                </div>
                <div v-if="item.ActionType == 'Stage Update'">
                  <span class="headerSName">Record moved from </span>
                  <v-chip
                    small-chips
                    style="
                      color: #ffffff;
                      background-color: #5444ab;
                      margin: 8px;
                      border-radius: 4px;
                    "
                    >{{ item.From_Stage }}</v-chip
                  >
                  <span class="headerSName"> to </span>
                  <v-chip
                    small-chips
                    style="
                      color: #ffffff;
                      background-color: #c323b2;
                      margin: 8px;
                      border-radius: 4px;
                    "
                    >{{ item.To_Stage }}</v-chip
                  >
                  <ul>
                    <li
                      v-if="
                        item.Old_Assignee_Group &&
                        item.New_Assignee_Group &&
                        item.New_Assignee_Group != item.Old_Assignee_Group
                      "
                    >
                      <span
                        v-if="
                          item.Old_Assignee_Group &&
                          item.New_Assignee_Group &&
                          item.New_Assignee_Group != item.Old_Assignee_Group
                        "
                      >
                        <span class="headerSName">
                          Assigned group changed from
                        </span>
                        <span style="color: ##212332; font-weight: 500">{{
                          item.Old_Assignee_Group
                        }}</span>
                        <span class="headerSName"> to </span>
                        <span style="color: ##212332; font-weight: 500">{{
                          item.New_Assignee_Group
                        }}</span>
                      </span>
                    </li>
                    <li
                      v-if="
                        item.Old_Assignee &&
                        item.New_Assignee &&
                        item.New_Assignee != item.Old_Assignee
                      "
                    >
                      <span
                        v-if="
                          item.Old_Assignee &&
                          item.New_Assignee &&
                          item.New_Assignee != item.Old_Assignee
                        "
                      >
                        <span class="headerSName"> Assignee changed from </span>
                        <span style="color: ##212332; font-weight: 500"
                          >{{ item.Old_Assignee }}
                        </span>
                        <span class="headerSName"> to </span>
                        <span style="color: ##212332; font-weight: 500"
                          >{{ item.New_Assignee }}
                        </span>
                      </span>
                    </li>
                    <li v-if="!item.Old_Assignee && item.New_Assignee">
                      <span v-if="!item.Old_Assignee && item.New_Assignee">
                        <span class="headerSName">Assignee set to </span>
                        <span style="color: ##212332; font-weight: 500">{{
                          item.New_Assignee
                        }}</span>
                      </span>
                    </li>
                    <li v-if="item.Old_Assignee && !item.New_Assignee">
                      <span
                        v-if="item.Old_Assignee && !item.New_Assignee"
                        class="headerSName"
                        >Unassigned from
                      </span>
                      <span
                        v-if="item.Old_Assignee && !item.New_Assignee"
                        style="color: ##212332; font-weight: 500"
                      >
                        {{ item.Old_Assignee }}</span
                      >
                    </li>
                  </ul>
                </div>
              </template>
            </v-data-table>
            <div
              style="text-align: right"
              class="pt-5"
              v-if="dataItems.length > 0 && !tokenFlag"
            >
              <v-btn
                depressed
                class="rounded-lg"
                color="#2A409A"
                style="color: white"
                @click="fetchUserlogs()"
                >{{ $t("Load More") }}
              </v-btn>
            </div>
          </v-row>
        </div>
      </div>
    </v-dialog>
    <div v-if="showMessageDialog">
      <MessageDialog
        :displayDialog="showMessageDialog"
        :messageObject="errorMessageShow ? errorMessageObject : okMessageObject"
        @closeMessageDialog="closeMessageDialog"
      ></MessageDialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import MessageDialog from "./MessageDialog.vue";
import { getFromLocalStorage } from "../store/localStorageInterface";
export default {
  props: ["listofApplication", "userlogsDialog", "userlogsData"],
  components: {
    MessageDialog,
  },
  data() {
    return {
      dialog: false,
      selectedApplication: "",
      applicationsObject: {},
      showMessageDialog: false,
      errorMessageObject: {},
      errorMessageShow: false,
      headers: [
        {
          text: "ID Number",
          align: "start",
          sortable: false,
          value: "RecordId",
        },
        { text: "Date and Time", value: "time", sortable: true },
        { text: "Event Type", value: "ActionType", sortable: false },
        { text: "Changes", value: "changes", sortable: false },
      ],
      loading: false,
      nextToken: null,
      tokenFlag: false,
      dataItems: [],
      menu: false,
      formatedDates: [],
      maxDate: new Date().toISOString().substr(0, 10),
      minDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 30
      )
        .toISOString()
        .substr(0, 10),
      searchFieldText: "Search by ID",
      searchValue: "",
      selectedDates: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "error",
      snackbarTimeout: 3000,
      required: []
    };
  },
  computed: {
    // Disables the fetch button if no application is selected
    isDisabled() {
      if(!this.selectedApplication || this.selectedApplication == '' || this.selectedDates.length == 1) {
        return true;
      }
      return false;
    },
    // Table displays a different text when no application is selected
    emptyText() {
      if(!this.selectedApplication || this.selectedApplication == '') {

        return "Please select an application to see the logs";
      } else {
        return "No data available"
      }
      
    },
    dateRangeText() {
      if (this.formatedDates && this.formatedDates.length == 2) {
        const date1 = new Date(this.formatedDates[0]);
        const date2 = new Date(this.formatedDates[1]);
        if (date1 > date2) {
          let tempDate = this.formatedDates[0];
          this.formatedDates[0] = this.formatedDates[1];
          this.formatedDates[1] = tempDate;
        }
        if (!this.formatedDates[1]) {
          this.formatedDates[1] = this.formatedDates[0];
        }
        return this.formatedDates.join(" ~ ");
      }
    },
  },
  methods: {
    clearDates() {
      this.selectedDates = [];
      this.snackbar = false;
    },
    isValidDate(date) {
      // Check if the date is within the allowed range of 30 days
      if (this.selectedDates.length === 1) {
        const startDate = new Date(this.selectedDates[0]);
        const endDate = new Date(date);
        const diff = Math.abs(endDate - startDate);
        return diff <= 29 * 24 * 60 * 60 * 1000; // 29 days in milliseconds
      } else {
        return true;
      }
    },
    onInput() {
      // Remove any selected dates outside of the allowed range
      if (this.selectedDates.length === 2) {
        const startDate = new Date(this.selectedDates[0]);
        const endDate = new Date(this.selectedDates[1]);
        const diff = Math.abs(endDate - startDate);
        if (diff > 29 * 24 * 60 * 60 * 1000) {
          // 29 days in milliseconds
          this.selectedDates.pop();
          this.showSnackbar(
            "Please Select a date that is within 30 days of the first selected date.",
            "error"
          );
        } else {
          this.snackbar = false;
          this.menu = false;
        }
      }
      this.formatedDates = [];
      this.selectedDates.map((item, index) => {
        this.formatedDates.push(this.formatDate(item));
      });
    },
    showSnackbar(message, color) {
      this.snackbarMessage = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    async resetAllFilters() {
      this.searchValue = "";
      this.tokenFlag = false;
      this.selectedDates = [];
      this.formatedDates = [];
      this.selectedApplication = "";
      this.nextToken = null;
      this.dataItems = [];
    },
    async getSearchRecordEmpty() {
      if (this.searchValue) {
        this.searchValue = "";
        this.nextToken = null;
        this.tokenFlag = false;
        this.dataItems = [];
        if(!this.selectedApplication) {
          return;
        }
        await this.fetchUserlogs();
      } else {
        this.searchValue = "";
      }
    },
    async getSearchRecord() {
      this.nextToken = null;
      this.tokenFlag = false;
      this.dataItems = [];
      if(!this.selectedApplication) {
        return;
      }
      await this.fetchUserlogs();
    },
    formatDate(date) {
      return moment(date).format("D MMM YY");
    },
    closeMessageDialog(emitObject) {
      var closeDialogFlag = false;
      if (emitObject && emitObject.errorDisplayMessage) {
        closeDialogFlag = emitObject.errorDisplayMessage;
      }
      this.showMessageDialog = emitObject.flag;
      this.$emit("closeDialog", closeDialogFlag);
    },
    ellipsisText(text, count) {
      if (text.length > count) {
        return `${text.slice(0, count)}…`;
      }
      return text;
    },
    clickClose() {
      this.dialog = false;
      this.$emit("closeUserLogsDialog", {
        userlogsDialog: false,
      });
    },
    getTimeAndDataAndChanges(dataItems) {
      dataItems.map((item) => {
        // '+' Unary Plus  wil do type casting to number
        const humanReadableTime = +item.UpdatedAt;
        const dateObj = new Date(humanReadableTime);
        const formattedDate = dateObj
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .split("/")
          .join("-");

        const formattedTime = dateObj.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
        item.time = [];
        item.time[0] = formattedDate;
        item.time[1] = formattedTime;
        if (item.ActionType == "Insert") {
          item["changes"] = `In ${
            this.applicationsObject[item.ApplicationId]
          }, created record in stage ${item.To_Stage} and assigned to group ${
            item.New_Assignee_Group
          } and  user ${item.New_Assignee}`;
        } else if (item.ActionType == "Edit") {
          if (!item.Old_Assignee) {
            item["changes"] = `In ${
              this.applicationsObject[item.ApplicationId]
            }, updated record. Assignee set to ${item.New_Assignee}`;
          } else if (item.Old_Assignee != item.New_Assignee) {
            item["changes"] = `In ${
              this.applicationsObject[item.ApplicationId]
            }, updated record. Assignee changed from ${item.Old_Assignee} to ${
              item.New_Assignee
            }`;
          } else {
            item["changes"] = `In ${
              this.applicationsObject[item.ApplicationId]
            }, updated record`;
          }
        } else {
          item[
            "changes"
          ] = `This ticket is moved from ${item.From_Stage} to ${item.To_Stage}`;
        }
      });
      return dataItems;
    },
    async getApplicationLogs() {
      this.nextToken = null;
      this.tokenFlag = false;
      this.dataItems = [];
      // Preventing query from running if no application selected
      if(!this.selectedApplication) {
        return;
      }
      await this.fetchUserlogs();
    },
    async fetchUserlogs() {
      this.loading = true;
      if (!this.tokenFlag) {
        await axios({
          method: "post",
          url: process.env.VUE_APP_BACKEND_URL + "/getAllUserLogs",
          data: {
            token: getFromLocalStorage("token"),
            authType: getFromLocalStorage("authType"),
            userId: getFromLocalStorage("userId"),
            applicationId: this.selectedApplication,
            nextToken: this.nextToken,
            dates: this.selectedDates,
            searchValue: this.searchValue,
          },
          headers: {
            "content-type": "application/json",
          },
        })
          .then(async (userLogsResponse) => {
            let responseData = await this.getTimeAndDataAndChanges(
              userLogsResponse.data.data
            );
            this.dataItems.push(...responseData);
            if (userLogsResponse.data.nextToken) {
              this.nextToken = userLogsResponse.data.nextToken;
            }

            if (userLogsResponse.data.nextTokenFlag) {
              this.tokenFlag = true;
            }
          })
          .catch((userLogsError) => {
            if (
              userLogsError.response &&
              userLogsError.response.status &&
              userLogsError.response.status == 401
            ) {
              this.$root.$emit("update_session_value", true);
            } else {
              this.errorMessageShow = true;
              if (
                userLogsError.response &&
                userLogsError.response.data &&
                userLogsError.response.data.errorObject
              ) {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code:
                    userLogsError.response.data.errorObject.error_code,
                  error_uuid:
                    userLogsError.response.data.errorObject.error_uuid,
                };
              } else {
                this.errorMessageObject = {
                  errorMessageShow: this.errorMessageShow,
                  error_code: "",
                  error_uuid: "",
                };
              }
              this.showMessageDialog = true;
            }
          });
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.required = [(v) => !!v || "Application is required"]
    this.dialog = this.$props.userlogsDialog;
    this.$props.listofApplication.map((app) => {
      this.applicationsObject[app.id] = app.name.en;
    });
  },
};
</script>

<style>
.headerSName {
  width: 237.58px;
  height: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #9798a3;
}
.buttons {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  width: 218px;
  height: 33px;
  background: #5444ab;
  border-radius: 4px;
}

/* width */
.userlogs_table .v-data-table__wrapper::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.userlogs_table .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 7px;
}

/* Handle */
.userlogs_table .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: rgb(42, 64, 154);
  border-radius: 7px;
}

/* Handle on hover */
.userlogs_table .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: rgb(42, 64, 154);
}
.dateRange_textField .v-text-field__details {
  display: none !important;
}
.v-application .v-snack__wrapper .v-snack__content {
  font-size: 14px !important;
  margin-left: 20px;
}
.custmon_datePicker .btn_actions {
  height: 30px !important;
  min-width: 64px;
  text-transform: capitalize;
}
.custmon_datePicker .v-date-picker-table {
  position: relative;
  padding: 0 12px;
  height: 200px;
}
ul li {
  margin-bottom: 8px; /* Add 10px of margin below each list item */
}
</style>
