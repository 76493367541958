<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title
          style="
            background-color: #ffffff;
            color: #2a409a;
            font-size: 20px;
            font-weight: 600;
            justify-content: center;
          "
          >{{ "Login" }}
        </v-card-title>
        <label style="color: #2a409a; padding-left: 20px">Email</label>
        <v-text-field
          style="padding-left: 20px; padding-right: 20px"
          label="Email address"
          v-model="email"
          :rules="emailRules"
          required
          solo
        ></v-text-field>
        <label style="color: #2a409a; padding-left: 20px">Password</label>
        <v-text-field
          style="padding-left: 20px; padding-right: 20px"
          label="Password"
          :append-icon= "showpassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpassword ? 'text' : 'password'"
          v-model="password"
          :rules="passwordRules"
          @click:append="showpassword = !showpassword"
          required
          solo
        ></v-text-field>
        <v-card-actions style="display: flex; justify-content: right">
          <div style="margin-right: 20px">
            <v-btn
              outlined
              style="
                background-color: #fffff;
                color: rgb(42, 64, 154);
                border: 1px solid rgb(42, 64, 154);
                width: 90px;
              "
              @click="clickCloseLoginDialog()"
              >{{ "Close" }}
            </v-btn>
          </div>
          <div>
            <v-btn
              style="
                background-color: rgb(42, 64, 154);
                color: #ffffff;
                width: 90px;
              "
              @click="ClickLoginBtn()"
              >{{ "Login" }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
var crypto = require("crypto");
export default {
  data() {
    return {
      email: "",
      password: "",
      showpassword:false,
      dialog: true,
      cipher: "",
      UserDetails:{},
      emailRules: [
        (v) => !!v || "Enter the email ID",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Enter a valid email ID",
      ],
      passwordRules: [
        (v) => !!v || "Password is required"
      ],
    };
  },
  methods: {
    clickCloseLoginDialog() {
      this.$emit("clickCloseLoginDialog", false);
    },
    ClickLoginBtn() {
      var Encrykey = process.env.VUE_APP_ENC_KEY;
      var ivData= process.env.VUE_APP_IV;
      this.cipher = crypto.createCipheriv('aes-256-cbc', Encrykey,  ivData);
      var encryptedValue = this.cipher.update(this.password, "utf-8", "base64");
      var encryptedToken = encryptedValue + this.cipher.final('base64');
      this.UserDetails={
        "userId":this.email,
        "UserEncrypassword":encryptedToken
      },
      this.$emit("clickCloseLoginDialog", false);
      this.$emit("loginWithEmail", this.UserDetails);
    }
  }
};
</script>
