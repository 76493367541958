<template>
  <div>
    <div v-if="showSpinner">
      <v-overlay :value="showSpinner">
        <SpinnerComponent></SpinnerComponent>
      </v-overlay>
    </div>

    <div v-else>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="441px">
          <v-card
            style="padding: 20px; ">
            <v-card-title
              class="text-poppins"
              style="
                font-family: Poppins;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.06em;
                text-align: center;
                padding:15px;
              "
            >
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-logout theme--light"
                style="
                  color: rgb(42, 64, 154);
                  caret-color: rgb(42, 64, 154);
                  margin-left: 12px;
                  margin-right: 10px;
                "
              ></i>
              Are you sure you want to logout ?
            </v-card-title>
            <v-card-actions style="padding:10px">
              <div class="text-poppins">
                <v-row dense>
                  <v-col cols="6"
                    ><v-btn
                    depressed
                      @click="Cancel()"
                      style="
                        min-width: 130px;
                        margin-left: 16px;
                        font-family: Poppins;
                      "
                      >Cancel</v-btn
                    ></v-col
                  >
                  <v-col cols="6" style="padding-left: 64px"
                    ><v-btn
                      class="text-poppins"
                      depressed
                      @click="logOut()"
                      style="
                        min-width: 130px;
                        font-family: Poppins;
                        background: rgb(42 64 154);
                        color: #ffffff;
                      "
                      >Log out</v-btn
                    ></v-col
                  >
                </v-row>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-row>
    </div>
  </div>
</template>

<script>

import SpinnerComponent from "../components/SpinnerComponent.vue";
import {getFromLocalStorage, removeFromLocalStorage} from "../store/localStorageInterface"
export default {
  components:{
    SpinnerComponent,
  },
  data() {
    return {
      dialog: true,
      showSpinner:false,
    };
  },
  methods: {
    Cancel() {
      this.$emit("closeLogOutDialog", false);
    },
    logOut() {
      this.showSpinner = true;
      this.$emit('loggedOut');
      this.$store.dispatch(
        "replaceNotifications", 
        {
          'read': {
            'notifications':[],
            'lastEvaluatedKey': '',
          },
          'unread': {
            'notifications':[],
            'lastEvaluatedKey': '',
          },
          'breach': {
            'notifications':[],
            'lastEvaluatedKey': '',
          }
        }
      );
      removeFromLocalStorage("userId");
      removeFromLocalStorage("userGroup");
      removeFromLocalStorage("fullName");
      removeFromLocalStorage("loginImage");
      let authType = getFromLocalStorage("authType");
      let secondarySupportLanguage = getFromLocalStorage("secondarySupportLanguage");
      if (secondarySupportLanguage) {
        removeFromLocalStorage('secondarySupportLanguage');
      }
      let idToken = getFromLocalStorage("idToken");
      if (authType == "onelogin" && idToken) {
        removeFromLocalStorage("token");
        removeFromLocalStorage("authType");
        window.location.href =
          process.env.VUE_APP_ONELOGIN_BASEURL +
          "/oidc/2/logout?post_logout_redirect_uri=" +
          process.env.VUE_APP_ONELOGIN_CALLBACK_URL +
          "&id_token_hint=" +
          idToken;
      } else {
        if(authType == 'gauth'){
          google.accounts.id.disableAutoSelect();
        }
        removeFromLocalStorage("token");
        removeFromLocalStorage("authType");
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
};
</script>
