export const setToLocalStorage = ( identifier, value ) => {
    let expiryDate = new Date(Date.now());
    // setting the next day same time / 24 h as the expiry
    expiryDate.setDate(expiryDate.getDate() +1);
    let expiryTimeInEpoc = Math.floor(expiryDate.getTime()/1000);
    let ObjToStore = {
        'value': value,
        'Expiry':  expiryTimeInEpoc
    };
    // for storing the data with the expiring time in the localStorage
    localStorage.setItem(identifier, JSON.stringify(ObjToStore));
};

export const getFromLocalStorage = (identifier) => {
    let currentEpoc = Math.floor(new Date().getTime() / 1000);
    let StoredObject = localStorage.getItem(identifier);
    // checking for the data presence
    if ( !StoredObject ){
        return '';
    }
    StoredObject = JSON.parse(StoredObject);
    // checking the expiry presence and expiry time from the stored object
    if (!('Expiry' in StoredObject) || currentEpoc > StoredObject['Expiry']) {
        localStorage.removeItem(identifier);
        return '';
    }
    // returning the valid stored value
    return StoredObject['value'];
};

export const removeFromLocalStorage = (identifier) => {
    return localStorage.removeItem(identifier);
};
